/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type * as O365OfflineDataRecordModule from "o365.pwa.declaration.sw.O365OfflineDataRecord.d.ts";

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    function restructureRecordForOfflineDB(record: O365OfflineDataRecordModule.IO365OfflineDataRecord): O365OfflineDataRecordModule.IO365FlatOfflineDataRecord | null;
    function restructureRecordForOfflineDB(record: O365OfflineDataRecordModule.IO365OfflineDataFileRecord): O365OfflineDataRecordModule.IO365FlatOfflineDataFileRecord | null;
    function restructureRecordForOfflineDB(record: O365OfflineDataRecordModule.IO365OfflineDataRecord | O365OfflineDataRecordModule.IO365OfflineDataFileRecord): O365OfflineDataRecordModule.IO365FlatOfflineDataRecord | O365OfflineDataRecordModule.IO365FlatOfflineDataFileRecord | null {
        if (!("JsonData" in record)) {
            self.o365.logger.error("OfflineData must contain property JsonData");
            return null;
        }

        const jsonData = JSON.parse(record.JsonData);

        const formattedObject = {
            ...jsonData
        }


        const prefix = "O365_";

        for (const [key, value] of Object.entries(record)) {
            formattedObject[`${['FileName', 'FileRef', 'FileSize', 'Extension', 'FileUpdated'].includes(key) ? '' : prefix}${key}`] = value;
        }

        return formattedObject;
    }

    function restructureRecordForOnlineDB(record: O365OfflineDataRecordModule.IO365FlatOfflineDataRecord): O365OfflineDataRecordModule.IO365OfflineDataRecord;
    function restructureRecordForOnlineDB(record: O365OfflineDataRecordModule.IO365FlatOfflineDataFileRecord): O365OfflineDataRecordModule.IO365OfflineDataFileRecord;
    function restructureRecordForOnlineDB(record: O365OfflineDataRecordModule.IO365FlatOfflineDataFileRecord | O365OfflineDataRecordModule.IO365FlatOfflineDataRecord): O365OfflineDataRecordModule.IO365OfflineDataFileRecord | O365OfflineDataRecordModule.IO365OfflineDataRecord {
        const prefix = "O365_";
        const jsonData: { [key: string]: any } = {};

        const newRecord = Object.keys(record).reduce((accumulator, currentValue, _currentIndex, _keys) => {
            if (currentValue.startsWith(prefix)) {
                accumulator[currentValue.slice(prefix.length)] = record[currentValue];
            } else if (['FileName', 'FileRef', 'FileSize', 'Extension', 'FileUpdated'].includes(currentValue)) {
                accumulator[currentValue] = record[currentValue];
            } else {
                jsonData[currentValue] = record[currentValue];
            }

            return accumulator;
        }, <{ [key: string]: any }>{}) as O365OfflineDataRecordModule.IO365OfflineDataFileRecord | O365OfflineDataRecordModule.IO365OfflineDataRecord;

        newRecord.JsonData = JSON.stringify(jsonData);

        return newRecord;
    }
    
    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.O365OfflineDataRecord.d.ts')>({ restructureRecordForOfflineDB, restructureRecordForOnlineDB });
})();
